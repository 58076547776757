<template>
  <v-container id="reset-password-confirm-view" class="fill-height" tag="section">
    <v-row justify="center">
      <v-col cols="12" md="9" lg="6">
        <v-slide-y-transition appear>
          <v-card class="pa-3 pa-md-10 mx-sm-auto" light>
            <template #heading>
              <div class="text-center pa-5">
                <v-list-item-title class="text-h3">
                  <strong class="mr-1 white--text font-weight-black">{{
                    $t("signin.forget.your.password")
                    }}</strong>
                </v-list-item-title>
              </div>
            </template>
            <validation-observer ref="observer" v-slot="{ invalid, handleSubmit }">
              <v-form ref="form" autocomplete="on" @submit.prevent="handleSubmit(resetPassword)">
                <v-card-text class="text-center">
                  <div class="text-center font-weight-light"></div>

                  <v-col cols="12">
                    <validation-provider v-slot="{ errors }" name="email" rules="required|email" :debounce="200">
                      <v-text-field class="mt-10" id="user-email" v-model="email" :label="$t('signin.email')"
                        autocomplete="user-email" color="secondary" :placeholder="$t('signin.email')"
                        :error-messages="errors" />
                    </validation-provider>
                  </v-col>

                  <v-btn color="secondary" rounded large type="submit" :disabled="invalid" :loading="loading"
                    class="mr-2">
                    {{ $t("signin.reset.password") }}
                  </v-btn>
                  <v-btn color="secondary" depressed rounded large text @click="signin">
                    {{ $t("signin.button") }}
                  </v-btn>
                </v-card-text>
              </v-form>
            </validation-observer>
          </v-card>
        </v-slide-y-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { resetPassword } from "@/services/auth.service"
import vue from "vue"

export default {
  name: "PasswordResetConfirm",

  data: () => ({
    loading: false,
    email: null
  }),
  beforeMount() {},
  methods: {
    signin() {
      this.$router.push({ name: "SignIn" })
    },
    resetPassword() {
      if (this.loading) {
        return
      }
      if (this.$refs.form.validate()) {
        this.loading = true
        resetPassword(this.email)
          .then(response => {
            console.log('response :>> ', response);
            this.$dialog.notify.info(response.detail, {
                timeout: 10000
              })
            this.$router.push({ name: "SignIn" })
          })
          .catch(error => {
            this.loading = false
            vue.$log.error(`Incorect login/password ${error}`, error.response?.data)
            const data = error.response?.data
            if (data == null) {
              return
            }
            if (data.email) {
              for (const message of data.email) {
                this.$dialog.notify.error(message, {
                  timeout: 10000
                })
              }
            }
          })
      }
      return false
    }
    // loginMock: function () {
    //
    //   this.$store.dispatch(AUTH_REQUEST, { username, password }).then(() => {
    //     this.$router.push('/')
    //   })
    // }
  }
}
</script>
